import type { FC, InputHTMLAttributes } from "react"
import { classNames } from "~/utils/style"

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | null
  inputContainerStyles?: string | null
  inputStyles?: string | null
  label?: string | null
  headerAction?: JSX.Element | null
  id?: string
  leftIcon?: JSX.Element | null
  rightIcon?: JSX.Element | null
}

const TextField: FC<Props> = ({
  error,
  headerAction,
  id,
  inputContainerStyles,
  inputStyles,
  label,
  leftIcon,
  rightIcon,
  ...props
}) => {
  return (
    <div className={classNames("flex flex-1 flex-col", props.className ?? "")}>
      <div className="flex justify-between">
        {label ? (
          <label htmlFor={id} className="block text-sm font-medium leading-6">
            {label}
          </label>
        ) : null}
        {headerAction}
      </div>
      <div className={classNames("relative mt-1", inputContainerStyles ?? "")}>
        {leftIcon ? (
          <div className="absolute inset-y-1 left-2 flex items-center">
            {leftIcon}
          </div>
        ) : null}
        <input
          {...props}
          id={id}
          className={classNames(
            leftIcon ? "pl-10" : "",
            rightIcon ? "pr-20" : "",
            "block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-inset sm:text-sm sm:leading-6",
            inputStyles ?? "",
          )}
        />
        {rightIcon ? (
          <div className="absolute inset-y-1 right-1 flex items-center">
            {rightIcon}
          </div>
        ) : null}
        {error ? (
          <p className="mt-2 text-sm text-red-600" role="alert">
            {error}
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default TextField
